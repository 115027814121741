
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Hydrate } from '@tanstack/react-query';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { AxiosInstance } from 'axios';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { IncomingMessage, ServerResponse } from 'http';
import { AxiomWebVitals } from 'next-axiom';
import useTranslation from 'next-translate/useTranslation';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
// eslint-disable-next-line @next/next/no-document-import-in-page
import type { DocumentContext } from 'next/document';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { lazy, useEffect } from 'react';
import { AuthService, ShopService, UserService } from '@core/api';
import { ToastWrapper } from '@core/component';
import { Translate, themeMediaQueries } from '@core/constant';
import { Logger } from '@core/logger';
import { ProviderApp } from '@core/provider';
import { generateUUID } from '@core/security';
import { EStorageKey } from '@core/storage';
import { DeviceSize, EAccessRoute, ECountry, ECurrency, EDevice, EHttpStatusCode, ELanguage, ELanguageTags, ELayout, EMetal, ERouting, EUserStatus, ErrorHandled, PageProps, SessionSSR, } from '@core/type';
import { ShopSubcategoriesResponse, UserEntity } from '@core/type/api';
import { DEFAULT_CURRENCY, getDefaultKey, getKey, getLanguageTags, getTypeRoute, setRefreshTokenPromise, uppercaseKeysInResObj, } from '@core/util';
import { PopupWrapper } from '@core/widget';
import { defaultRoute, getContentSecurityPolicy, pathTranslations } from '../constants';
import { getEnvironmentPrivate, getEnvironmentPublic, initializeHttpServer } from '../helpers';
import { ContainerFirstLoad } from '../layout';
import { ProviderMain } from '../providers';
import { GlobalStyle, themeColorList, themeLG, themeMD, themeSM, themeXL, themeXS, } from '../themes';
import { ViewNotFound } from '../view';
import { Maintenance } from './maintenance';
// eslint-disable-next-line @typescript-eslint/naming-convention
const ToastLazy = lazy(() => import('@core/component').then(({ Toast }) => ({ default: Toast })));
const ToastCartLazy = lazy(() => 
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-assignment
import('core-widget/src/cart-popup').then(({ CartPopup }) => ({ default: CartPopup })));
export type AppPropsWithLayout<P extends PageProps<UserEntity>> = AppProps<P> & MyAppProps;
export type DocumentPropsWithLayout<P extends PageProps<UserEntity>> = AppProps<P> & MyDocumentProps;
export type MyDocumentProps = {
    nonce: string;
    csrfToken: string;
    language: Exclude<ELanguageTags, 'default'>;
};
export type MyAppProps = SessionSSR<UserEntity> & MyDocumentProps & {
    currency: ECurrency;
    deviceDetected?: DeviceSize;
    deviceType?: EDevice;
    isBotDetected?: boolean;
    userLocation: ECountry;
    keyRoute: ERouting;
    shouldSignIn?: boolean;
    userStatus?: EUserStatus;
    subcategories?: ShopSubcategoriesResponse;
};
const getTranslate = (keyRoute: ERouting, maintenanceMode?: boolean): string => {
    if (maintenanceMode) {
        return Translate.page.MAINTENANCE;
    }
    switch (keyRoute) {
        case ERouting.NOT_FOUND:
            return Translate.page.NOT_FOUND;
        case ERouting.INTERNAL_ERROR:
            return Translate.page.INTERNAL_ERROR;
        default:
            return getTypeRoute(keyRoute).translation || Translate.page.NOT_FOUND;
    }
};
const REGEXP = /^[^/]*\/[^/]*/g;
type CookieType = {
    cookies: Partial<{
        [key: string]: string;
    }>;
};
const getKeyRoute = (route: string, language: Exclude<ELanguageTags, 'default'>) => {
    return route ? getKey(pathTranslations[language], route) : null;
};
export const getSession = async ({ axiosInstance, setStatusUser, resetStatusUser, ctx, refreshToken, locale, currency, deviceType, tokenAuthorizationApi, }: {
    locale: ELanguage;
    currency: ECurrency;
    axiosInstance: AxiosInstance;
    ctx?: DocumentContext;
    refreshToken?: string;
    tokenAuthorizationApi?: string;
    setStatusUser: (userStatus: EUserStatus) => void;
    resetStatusUser: () => void;
    deviceType?: EDevice;
}): Promise<SessionSSR<UserEntity>> => {
    const language = getLanguageTags(locale);
    try {
        const user = 
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (ctx?.AppTree?.defaultProps?.pageProps?.user as UserEntity) ||
            (await UserService.getProfile(axiosInstance, language));
        return { isLogged: true, isReady: true, user };
    }
    catch (error) {
        if ((error as ErrorHandled)?.code === EHttpStatusCode.UNAUTHORIZED) {
            try {
                const refreshTokenPromise = AuthService.refreshTokenSSR(axiosInstance, language, refreshToken);
                setRefreshTokenPromise(refreshTokenPromise);
                const data = await refreshTokenPromise;
                setRefreshTokenPromise(undefined);
                setCookie(EStorageKey.TOKEN, data.token, {
                    req: ctx.req,
                    res: ctx.res,
                    httpOnly: false
                });
                setCookie(EStorageKey.REFRESH_TOKEN, data.refreshToken, {
                    req: ctx.req,
                    res: ctx.res,
                    httpOnly: false
                });
                const axiosNewInstance = initializeHttpServer({
                    locale,
                    tokenAuthorizationApi,
                    currency,
                    contentType: 'application/json',
                    deviceType,
                    token: data.token
                });
                const user = await UserService.getProfile(axiosNewInstance, language);
                return { isLogged: true, isReady: true, user };
            }
            catch (e) {
                deleteCookie(EStorageKey.TOKEN);
                deleteCookie(EStorageKey.REFRESH_TOKEN);
                return { isLogged: false, isReady: true, user: null };
            }
        }
        else if ((error as ErrorHandled)?.userStatus) {
            setStatusUser((error as ErrorHandled).userStatus);
        }
        else {
            resetStatusUser();
        }
        return { isLogged: false, isReady: true, user: null };
    }
};
const getSessionUser = async (keyRoute: ERouting, ctx: DocumentContext, req: IncomingMessage & CookieType, locale: ELanguage, currency: ECurrency, accessRoute: EAccessRoute, tokenAuthorizationApi: string, deviceType: EDevice, setStatusUser: (userStatus: EUserStatus) => void, resetStatusUser: () => void): Promise<SessionSSR<UserEntity>> => {
    let session: SessionSSR<UserEntity> = { isLogged: false, isReady: true, user: null };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const pathname = (ctx as any)?.AppTree()?.props?.children?.props?.router?.state?.pathname;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const user = (ctx as any)?.AppTree()?.props?.children?.props?.router?.components?.[pathname]
        ?.props?.user as UserEntity;
    if (user) {
        return { user, isLogged: true, isReady: true, shouldRedirectSignIn: false };
    }
    const token = getCookie(EStorageKey.TOKEN, {
        req,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        res: ctx?.res as ServerResponse<IncomingMessage>
    });
    if (token && keyRoute !== ERouting.VERIFY_AUTH) {
        const axiosInstance = initializeHttpServer({
            locale,
            currency,
            contentType: 'application/json',
            token,
            deviceType
        });
        session = await getSession({
            axiosInstance,
            tokenAuthorizationApi,
            setStatusUser,
            resetStatusUser,
            deviceType,
            refreshToken: getCookie(EStorageKey.REFRESH_TOKEN, {
                req,
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                res: ctx?.res as ServerResponse<IncomingMessage>
            }),
            ctx,
            locale,
            currency
        });
    }
    if (!session.isLogged && accessRoute === EAccessRoute.CONNECTED) {
        return { ...session, shouldRedirectSignIn: true };
    }
    return session;
};
export const MyApp = ({ 
// eslint-disable-next-line @typescript-eslint/naming-convention
Component, pageProps, nonce, csrfToken, language, currency, user, isLogged, isReady, keyRoute, shouldSignIn, userStatus, subcategories, deviceType, shouldRedirectSignIn, router: { query, asPath }, }: AppPropsWithLayout<PageProps<UserEntity>>) => {
    const { isBotDetected, deviceDetected } = pageProps;
    const env = getEnvironmentPublic();
    const { isReady: isReadyPage, push } = useRouter();
    const { t } = useTranslation(getTranslate(keyRoute, env.maintenanceMode));
    const { t: tCommon } = useTranslation(Translate.common.COMMON);
    useEffect(() => {
        if (shouldRedirectSignIn) {
            void push(pathTranslations[language][ERouting.SIGN_IN]);
        }
    }, [shouldRedirectSignIn]);
    useEffect(() => {
        Logger.initialize(env.logger);
        if (isLogged && isReady && user) {
            Logger.setUser(user);
        }
        if (asPath.startsWith('/backoffice')) {
            window.location.href = `${env.apiHost}${asPath}`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0"/>
        {env.maintenanceMode ||
            (!getTypeRoute(keyRoute).keyRouteDynamic && (<>
              <title>{t('titleMetaTag')}</title>
              <meta name="description" content={t('descriptioneMetaTag')}/>
            </>))}
      </Head>
      <AxiomWebVitals />
      <ProviderApp isLogged={isLogged} isReady={isReady} clientIp={pageProps?.clientIp} user={user} isBotDetected={isBotDetected} keyNonce={nonce} csrfToken={csrfToken} cdn={env.cdn} currencyInitialize={currency || pageProps?.currency} keyRouteInitialize={keyRoute} defaultRouteInitialize={defaultRoute} pathTranslationsInitialize={pathTranslations} queryInitialize={query} themeMediaQueries={themeMediaQueries} deviceDetected={deviceDetected} themeXL={themeXL} themeLG={themeLG} themeMD={themeMD} themeSM={themeSM} themeXS={themeXS} themeColorList={themeColorList} ToastComponent={ToastLazy} ToastComponentWrapper={ToastWrapper} PopupComponentWrapper={PopupWrapper} ToastComponentCart={ToastCartLazy} staleTimeClient={env.staleTimeClient} apiHost={env.apiHost} apiBase={env.apiBase} apiVersion={env.apiVersion} isProductionServer={env.isProductionServer} language={language} currency={currency || pageProps?.currency} tCommon={tCommon} deviceType={deviceType} alternates={pageProps?.alternates} metas={pageProps?.metas} breadcrumbs={pageProps?.breadcrumbs}>
        <>
          <GlobalStyle />
          {env.maintenanceMode ? (<Maintenance />) : keyRoute === ERouting.SAFERPAY_REDIRECTION ||
            keyRoute === ERouting.SAFERPAY_TRANSACTION ? (<Hydrate state={pageProps?.dehydratedState}>
              <Component {...pageProps}/>
            </Hydrate>) : (<Hydrate state={pageProps?.dehydratedState}>
              <ProviderMain subcategories={subcategories} isBotDetected={isBotDetected} recaptchaKey={env.recaptchaKey} nonce={nonce} blogCategories={pageProps?.blogCategories} shouldSignIn={shouldSignIn} userStatus={userStatus} isDraft={pageProps?.isDraft}>
                <ContainerFirstLoad article={pageProps?.article} product={pageProps?.product} price={pageProps?.price} cmsPageId={pageProps?.pageId} breadcrumbs={pageProps?.breadcrumbs} isLoading={!isReadyPage} renderNoAccess={<ViewNotFound />}>
                  <Component {...pageProps}/>
                </ContainerFirstLoad>
              </ProviderMain>
            </Hydrate>)}
        </>
      </ProviderApp>
      {env.enableAnalytics && (<>
          <Analytics />
          <SpeedInsights />
        </>)}
    </>);
};
MyApp.shouldSignIn = false;
MyApp.userStatus = null as EUserStatus;
MyApp.redirectToBackoffice = (ctx: DocumentContext, apiHost: string) => {
    ctx?.res?.writeHead(EHttpStatusCode.PERMANENT_REDIRECT, {
        Location: `${apiHost}${ctx.asPath.replace(REGEXP, '')}`
    });
    ctx.res.statusCode = EHttpStatusCode.PERMANENT_REDIRECT;
    ctx?.res?.end();
};
MyApp.setStatusUser = (userStatus: EUserStatus) => {
    MyApp.userStatus = userStatus;
};
MyApp.resetStatusUser = () => {
    MyApp.userStatus = null;
};
MyApp.getCookie = (cookie: string, name: string) => {
    if (cookie) {
        const regex = new RegExp(`(^| )${name}=([^;]+)`);
        const match = cookie.match(regex);
        if (match) {
            return match[2];
        }
    }
    return null;
};
MyApp.getInitialProps = async (context: AppContext) => {
    const { tokenAuthorizationApi, deviceType } = getEnvironmentPrivate();
    const nonce = generateUUID();
    const csrfToken = generateUUID();
    context?.ctx?.res?.setHeader('Content-Security-Policy', getContentSecurityPolicy(nonce, getEnvironmentPublic())
        .replace(/\s{2,}/g, ' ')
        .trim());
    const language = getLanguageTags(context?.ctx?.locale as ELanguage);
    const keyRoute = getKeyRoute(context?.ctx?.asPath, language) ||
        getDefaultKey(context?.ctx?.pathname, defaultRoute);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const pathname = (context?.ctx as any)?.AppTree()?.props?.children?.props?.router?.state
        ?.pathname;
    const currency: ECurrency = (context?.ctx?.query?.currency_iso as ECurrency) ||
        (getCookie(EStorageKey.CURRENCY, {
            req: context?.ctx?.res?.req,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            res: context?.ctx?.res as ServerResponse<IncomingMessage>
        }) as ECurrency) ||
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        ((context?.ctx as any)?.AppTree()?.props?.children?.props?.router?.components?.[
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-unsafe-member-access,
        pathname as any]?.props?.currency as ECurrency) ||
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (context?.ctx?.AppTree?.defaultProps?.pageProps?.currency as ECurrency);
    const shouldSignIn = MyApp.shouldSignIn;
    const userStatus = MyApp.userStatus;
    const routeTypeProps = getTypeRoute(keyRoute);
    const session = await getSessionUser(keyRoute, context?.ctx as DocumentContext, context?.ctx?.res?.req as IncomingMessage & CookieType, context?.ctx?.locale as ELanguage, currency || DEFAULT_CURRENCY, routeTypeProps?.accessRoute, tokenAuthorizationApi, deviceType, MyApp.setStatusUser, MyApp.resetStatusUser);
    let subcategories: ShopSubcategoriesResponse | undefined = undefined;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    subcategories =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        (context?.ctx as any)?.AppTree()?.props?.children?.props?.router?.components?.[
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-unsafe-member-access
        pathname as any]?.props?.subcategories ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (context?.ctx?.AppTree?.defaultProps?.pageProps?.subcategories as ShopSubcategoriesResponse);
    if (!subcategories && routeTypeProps?.header?.type === ELayout.DEFAULT) {
        const axiosInstance = initializeHttpServer({
            locale: context?.ctx?.locale as ELanguage,
            currency: currency || DEFAULT_CURRENCY,
            contentType: 'application/json',
            deviceType
        });
        try {
            const subcategoriesData = await ShopService.getSubcategories(axiosInstance, language);
            subcategories = uppercaseKeysInResObj<EMetal, ShopSubcategoriesResponse>(subcategoriesData);
        }
        catch (e) {
            Logger.logError(e, { isException: true });
        }
    }
    if (context?.ctx?.query?.nextInternalLocale) {
        delete context?.ctx?.query.nextInternalLocale;
    }
    const ctx = await App.getInitialProps(context);
    return {
        ...ctx,
        ...session,
        csrfToken,
        nonce,
        language,
        currency,
        deviceType,
        keyRoute,
        shouldSignIn,
        userStatus,
        subcategories
    };
};
const __Next_Translate__Page__1928ac680cf__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1928ac680cf__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  